<template>
  <div>
    <!-- No record card::begin -->
    <div class="card card-dark-withbg" v-if="!loading && data.length == 0">
      <div class="card-body p-0">
        <div class="card-px text-center py-20 my-10">
          <h2 class="fs-2x fw-bolder mb-10" v-if="moduleName">
            {{ moduleName }}
          </h2>
          <p class="text-gray-400 fs-4 fw-bold">
            <template v-if="moduleSearch">
              Aramanıza uygun bir kayıt bulamadık.
            </template>
            <template v-else> Henüz eklenmiş bir kaydınız yok. </template>
          </p>
          <p
            class="text-gray-400 fs-4 fw-bold"
            v-if="moduleCreateEvent === true"
          >
            Kaydınızı oluşturun ve hemen kullanmaya başlayın!
          </p>
          <!-- <div
            @click="openCreateModal"
            v-if="moduleCreateEvent === true"
            class="btn btn-primary mt-10 btn-sm rounded-sm"
          >
            <i class="fas fa-plus-circle"></i> Yeni Ekle
          </div> -->
          <div
            v-if="showFilterClearButton"
            @click="clearFilter"
            class="btn btn-danger mt-10 ml-3 btn-sm rounded-sm"
          >
            <i class="fas fa-times-circle"></i> Filtreleri Temizle
          </div>
        </div>
      </div>
    </div>
    <!-- No record card::end -->

    <div class="list" v-else>
      <div class="list-header card" :style="headerStyle">
        <div class="show-detail-button">Detay</div>
        <div
          class="item font-weight-boldest"
          v-for="(head, hI) in header"
          :key="hI"
          v-if="removeHideElement(head)"
        >
          
          <b-form-group v-if="head.checkboxField" class="m-0">
            <label class="checkbox checkbox-square">
              <input
                type="checkbox"
                id="allSelectedInCheckbox"
                v-model="allSelectedInCheckbox"
                @click="checkboxAllClicked"
              />
              <span></span>
            </label>
          </b-form-group>
          <slot :name="`header.${head.value}`" :item="head" v-else>
            <div
              class="d-flex"
              :class="[
                head.value === 'action'
                  ? 'justify-content-end mr-4'
                  : 'justify-content-between',
              ]"
            >
              <div style="cursor: pointer" @click="sortTable(head)">
                {{ head.text ? head.text : "" }}
                <button v-if="head.sortable">
                  <span
                    v-if="sortOrder === 'asc' && sortField === head.value"
                    class="sort-icons"
                  >
                    <i class="fas fa-arrow-up"></i>
                  </span>
                  <span class="sort-icons" v-else>
                    <i class="fas fa-arrow-down"></i>
                  </span>
                </button>
              </div>
            </div>
          </slot>
        </div>
      </div>

      <div class="loading" v-if="loading">
        <div class="loading_line_wrapper">
          <div class="loading_line">
            <div class="loading_line_inner loading_line_inner--1"></div>
            <div class="loading_line_inner loading_line_inner--2"></div>
          </div>
        </div>
        <span
          class="text font-size-md font-weight-bold text-muted p-2"
          v-if="data.length <= 0"
          >Yükleniyor</span
        >
      </div>

      <Card v-else>
        <div class="list-content">
          <div
            v-for="(item, index) in internalData.slice(
              0,
              pagination.list_length
            )"
          >
            <div class="item" :style="headerStyle">
              <div class="show-detail-button">
                <button class="show-button" @click="showDetailHandler(index)">
                  +
                </button>
              </div>
              <div
                class="field text-dark font-weight-normal"
                v-for="(head, dI) in header"
                :key="`${dI}${index}`"
                v-if="removeHideElement(head)"
              >
                <!-- copyField will work if true -->
                <CopyButtonLabel
                  v-if="head.copyField"
                  :value="item[head.value] ? item[head.value] : 'N/A'"
                />

                <b-form-group v-else-if="head.checkboxField" class="m-0">
                  <label class="checkbox checkbox-square">
                    <input
                      type="checkbox"
                      v-model="selectedCheckbox[index]"
                      ref="`selectedCheckbox[${index}]`"
                      :id="`selectedCheckbox[${index}]`"
                      :data-id="item[head.value]"
                      @click="checkboxClicked(index)"
                    />
                    <span></span>
                  </label>
                </b-form-group>

                <template v-else>
                  <router-link
                    v-if="detailUrl && detailUrl != '' && head.clicked"
                    :to="`${detailUrl}/${item.id}`"
                    class="text-dark font-weight-normal"
                  >
                    <slot :name="`item.${head.value}`" :item="item">
                      {{
                        item[head.value]
                          ? $customFunctions.controlSpace(item[head.value])
                          : "N/A"
                      }}
                    </slot>
                  </router-link>

                  <!-- slot will work if default -->
                  <span
                    :class="{ 'cursor-pointer': head.clicked }"
                    @click="clickField(item, head)"
                    v-else
                  >
                  
                    <slot :name="`item.${head.value}`" :item="item">
                      {{
                        item[head.value]
                          ? $customFunctions.controlSpace(item[head.value])
                          : "N/A"
                      }}
                    </slot>
                  </span>
                </template>
              </div>
            </div>
            <div class="list-detail-view" :id="'show-detail-' + index">
              <div class="detail-view-table">
                <table>
                  <tr v-for="(head, dI) in header" class="d-flex align-items-center text-center">
                    <th  class="mr-4" >
                      {{ head.text ? head.text : "Detay" }} :
                    </th>
                    <th class="ml-0 " style="flex:1;display: flex;">
                      <slot class="" :name="`item.${head.value}`" :item="item">
                        {{
                          item[head.value]
                            ? $customFunctions.controlSpace(item[head.value])
                            : "N/A"
                        }}
                      </slot>
                    </th>
                    

                  </tr>
                </table>
              </div>
            </div>
            <slot
              name="subRow"
              :item="item"
              :selectedItemId="selectedItemId"
              :openNewItem="openNewItem"
            ></slot>
          </div>

          <span
            v-if="data.length <= 0 && !loading"
            class="text-center font-size-md font-weight-bold text-muted p-1"
          >
            Kayıt bulunamadı.
          </span>
        </div>
      </Card>
    </div>

    <slot></slot>

    <CustomPagination
      v-if="
        !loading &&
        data.length > 0 &&
        pagination &&
        pagination.constructor === Object &&
        Object.keys(pagination).length > 0
      "
      :page="pagination.current_page"
      :totalPages="pagination.total_pages"
      :totalItems="pagination.total_items"
      @changePage="changePage"
    />
  </div>
</template>

<script>
export default {
  name: "List",
  props: {
    header: Array,
    data: Array,
    pagination: Object,
    loading: Boolean,
    detailUrl: "",
    moduleName: "",
    moduleCreateEvent: {
      type: Boolean | String,
      default: false,
    },
    moduleSearch: "",
    selectedItemId: "",
    openNewItem: {
      type: Boolean,
      default: false,
    },
    showFilterClearButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      checkboxSelectedIds: [],
      allSelectedInCheckbox: false,
      selectedCheckbox: [],
      sortField: "",
      sortOrder: "asc",
      internalData: [],
    };
  },
  computed: {
    headerStyle() {
      if (!this.header) return;

      let mappingHeader = this.header
        .map((item) => {
          return item.size ? item.size : "1fr";
        })
        .join(" ");

      return {
        gridTemplateColumns: mappingHeader,
      };
    },
  },
  created() {
    this.setDefaultCheckboxValues();
    this.internalData = [...this.data];
  },
  mounted(){
    this.$nextTick(() => {
      window.addEventListener('resize', this.removeHideElement);
    })

  },
  beforeDestroy() { 
    window.removeEventListener('resize', this.removeHideElement); 
  },

  methods: {
    setDefaultCheckboxValues() {
      if (!this.data) return;

      for (let index = 0; index < this.data.length; index++) {
        this.selectedCheckbox[index] = false;
      }

      this.checkboxSelectedIds = [];
    },
    changePage(value) {
      this.$emit("updatePage", { current_page: value });
      this.pagination.page = parseInt(value);
    },
    clickField(item, head) {
      if (!head.clicked) return false;

      this.$emit(`click`, item, head.value);
    },
    openCreateModal() {
      this.$emit("openCreateModal");
    },
    clearFilter() {
      this.$emit("clearFilter", true);
    },
    checkboxAllClicked() {
      setTimeout(() => {
        this.data.forEach((element) => {
          if (
            this.checkboxSelectedIds.includes(element.id) ==
            !this.allSelectedInCheckbox
          ) {
            document.querySelector(`[data-id='${element.id}']`).click();
          }
        });
      }, 10);
    },
    checkboxClicked(index) {
      setTimeout(() => {
        var clickedCheckbox = document.getElementById(
          `selectedCheckbox[${index}]`
        );
        let _id = clickedCheckbox.getAttribute("data-id");

        if (this.checkboxSelectedIds.includes(_id) === false) {
          this.checkboxSelectedIds.push(_id);

          if (this.selectedCheckbox.every((element) => element === true)) {
            //Eğer hepsi seçili ise, tümü seçili kısmını işaretlemek gerekiyor.
            let allCheck = document.getElementById("allSelectedInCheckbox");
            allCheck.checked = true;
            allCheck.dispatchEvent(new Event("change"));
          }
        } else {
          this.checkboxSelectedIds.splice(
            this.checkboxSelectedIds.indexOf(_id),
            1
          );
          //Eğer hepsi seçili kısmı işaretli ise onu kaldırmak gerekiyor.
          let allCheck = document.getElementById("allSelectedInCheckbox");
          allCheck.checked = false;
          allCheck.dispatchEvent(new Event("change"));
        }
      }, 10);
    },
    sortTable(head) {
      if (this.sortField === head.value) {
        this.sortOrder = this.sortOrder === "asc" ? "desc" : "asc";
      } else {
        this.sortOrder = "asc";
      }

      this.sortField = head.value;
      this.internalData = this.internalData
        .slice(0, this.pagination.list_length)
        .sort((a, b) => {
          if (a[head.value] > b[head.value])
            return this.sortOrder === "asc" ? 1 : -1;
          if (a[head.value] < b[head.value])
            return this.sortOrder === "asc" ? -1 : 1;
        });
    },
    showDetailHandler(index) {
      const element = document.querySelector("#show-detail-" + index);
      if (element.classList.contains("show")) {
        element.classList.remove("show");
      } else {
        element.classList.add("show");
      }
    },
    removeHideElement(item){
      let status = false
      if(window.innerWidth < 990){
        if(item?.hideMobile){
          status= false
        }else{
          status = true
        }
      }else{
        status = true
      }
      return status
    }
  },

  watch: {
    checkboxSelectedIds: function () {
      this.$emit("selectedItemIdsChanged", this.checkboxSelectedIds);
    },
    data: function () {
      this.setDefaultCheckboxValues();
      this.internalData = [...this.data];
    },
    
  },
};
</script>
<style lang="scss" scoped>
.sort-icons {
  margin-left: 1rem;
  i {
    font-size: 0.8rem !important;
    color: $dveb-primary;
  }
}
.list-detail-view {
  display: none;

  &.show {
    display: block !important;
  }
  .detail-view-table {
    width: 100%;
    padding: 20px 0 20px 20px;
    border-bottom: 1px solid $dveb-primary;
    table {
      width: 100%;
      tr{
        display: flex;
        padding: .5rem 0;
        border-bottom: 1px solid $dveb-divider;
        &:first-child{
          border-bottom:  0;
        }
      }
    }
  }
}
.show-detail-button {
  display: none;
}

@media (max-width: 990px) {
  .show-detail-button{
    display: block;
  }
  .list-header {
    grid-template-columns: 50px 1fr !important;
    .item {
      &:not(:nth-child(2)) {
        display: none;
        width: 100%;
        min-width: 100%;
      }
      &:first-child {
        display: block;
      }
    }
  }
  .list-content {
    .show-detail-button {
      display: block;
      .show-button {
        border: 1px solid $dveb-divider;
        margin-right: 5px;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        background-color: $dveb-info;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .item {
      grid-template-columns: 50px  1fr !important;
      .field {
        &:not(:nth-child(1)) {
          display: none;
          width: 100%;
          min-width: 100%;
        }
        &:nth-child(2) {
          display: block;
        }
        &:first-child {
          display: block;
        }
      }
    }
  }
}
</style>